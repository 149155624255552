const gigs = [
  {
    date: 'Sat, AUG 26',
    premises: 'Club Vatican',
    location: 'Helsinki, Finland'
  },
  {
    date: 'Sat, AUG 26',
    premises: 'Club Vatican',
    location: 'Helsinki, Finland'
  },
  {
    date: 'Sat, AUG 26',
    premises: 'Club Vatican',
    location: 'Helsinki, Finland'
  }
]

export default gigs